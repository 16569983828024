import React, { createContext } from 'react'
import { Route, Routes, useLocation} from 'react-router-dom';
import Home from './pages/home'
import About from './pages/about';
import Services from './pages/services';
import CaseStudies from './pages/caseStudies';
import CaseDetail from './pages/casedetail';
import ContactUs from './pages/contactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Header from './header';
import Footer from './footer';
import { useState } from 'react';
import { useEffect } from 'react';


const ServiceglobelId = createContext();

const App = () => {

  const [receivedServiceName, setreceivedServiceName]= useState(null)

  const [receivedServiceId, setReceivedServiceId] = useState(null);

    const handleServiceIdChange = (serviceId,servicename) => {
        setReceivedServiceId(serviceId);
        setreceivedServiceName(servicename);
    };

    const [receivedcaseDetailId, setReceivedcaseDetailId] = useState(null);
    const [receivedcaseDetailName, setReceivedcaseDetailName] = useState(null);

    const handlecaseDetailIdChange = (caseId, caseName) => {
        setReceivedcaseDetailId(caseId);
        setReceivedcaseDetailName(caseName);
    };

    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo({top: 0, behavior: 'smooth',} );
    }, [pathname]);

  return (
    <>
      <ServiceglobelId.Provider value={{receivedServiceId , receivedcaseDetailId, receivedServiceName, receivedcaseDetailName}}>
        <Header/>
          <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/services/:servicename' element={<Services onServiceIdChange={handleServiceIdChange}/>} />
              <Route path='/caseStudies' element={<CaseStudies />} />
              <Route path='/casedetail/:caseName' element={<CaseDetail oncaseDetailIdChange={handlecaseDetailIdChange}/>} />
              <Route path='/contactUs' element={<ContactUs />} />
              <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
          </Routes>
          <Footer />
      </ServiceglobelId.Provider>
    </>
  )
}

export default App
export {ServiceglobelId}
