import React from 'react'
import { useEffect } from 'react';
import detailpageApi from '../api/detailpageApi';
import { useParams } from 'react-router-dom';
import Motion from '../animation/Motion';

const CaseDetail = ({oncaseDetailIdChange}) => {
    const {caseName} = useParams();
    const singlepage = detailpageApi.find((curElem)=> {
        return curElem.casename === caseName
    });
    const {bannerImg,bannerHeading,bannerDetail,color_pallete, color1_Code,mainPageImg,secondImgHeading,secondImg,ThirdImgHeading,ThirdImg,projectsumHeading1,sumImg1,projectsumHeading2,sumImg2,projectsumHeading3,sumImg3,sumImg4, text_color, banner_white, text_direction, fourthImg, fifthImg, fourthImgHeading, fifthImgHeading, mob_project, web_project,screen_img,project_summary,sumImg5,projectsumHeading4,projectsumHeadingmain,projectsumPara,clientwork_para,bannerColor} = singlepage 

    useEffect(()=>{
        oncaseDetailIdChange(caseName);
    }, [caseName, oncaseDetailIdChange])

  return (
    <>

        
        <section className={`caseDetail-banner ${banner_white}`} 
            style={
                { backgroundImage: `url(${bannerImg})`
                , backgroundColor: `${bannerColor}`}
            }
        >
            <div className='container-fluid'>
                <div className={`row  ${text_direction}`}>
                    <div className='col-md-6'>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <h1 className={` ${text_color}`}>{bannerHeading}</h1>
                            <p className={` ${text_color}`}>{bannerDetail}</p>
                        </Motion>
                    </div>
                </div>
            </div>
        </section>
        {
            web_project &&
            <>
                <section className='clientWork'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 text-center mb-5 pb-3'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <h2 className='text-light-grey mb-3 pb-1'>Client Work</h2>
                                </Motion>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <p className='text-light-grey'>{clientwork_para}</p>
                                </Motion>
                            </div>
                            <div className='col-lg-6'>
                                <div className='color-pallete'>
                                    
                                    <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <h3 className='mb-4 pb-2 casedetail-project-heading'>Color Palette & Texture</h3>
                                    </Motion>
                                    {
                                        color_pallete.map((curElem) =>{
                                            return(
                                                <Motion key={curElem.id} classNames="transition-none" transition={{ duration: 0.4, delay: curElem.id * .1 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                                    <div className='color-boxes-main'>
                                                        <div className={color1_Code === "#ffffff" || "#FFFFFF" ? 'border color-box' : 'color-box'} style={{ backgroundColor: `${curElem.color_Code}`}}></div>
                                                        <p className='color-code'>{curElem.color_Code}</p>
                                                    </div>
                                                </Motion>
                                            )
                                        })
                                }
                                </div>
                                <div className=''>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <h3 className='casedetail-project-heading '>{secondImgHeading}</h3>
                                    </Motion>
                                    <img src={secondImg} className={secondImg === "" ? "w-100" : "w-100 project-imgs"} alt="" />
                                </div>
                                <div>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <h3 className='casedetail-project-heading '>{ThirdImgHeading}</h3>
                                    </Motion>
                                    <img src={ThirdImg} className={ThirdImg === "" ? "w-100" : "w-100 project-imgs"} alt="" />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className=''>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <h3 className='casedetail-project-heading '>Main Page</h3>
                                    </Motion>
                                    <img src={mainPageImg} className={mainPageImg === "" ? "w-100" : "w-100 project-imgs"} alt="" />
                                </div>
                                <div className=''>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <h3 className='casedetail-project-heading '>{fourthImgHeading}</h3>
                                    </Motion>
                                    <img src={fourthImg} className={fourthImg === "" ? "w-100" : "w-100 project-imgs"} alt="" />
                                </div>
                                <div className=''>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <h3 className='casedetail-project-heading '>{fifthImgHeading}</h3>
                                    </Motion>
                                    <img src={fifthImg} className={fifthImg === "" ? "w-100" : "w-100 project-imgs"} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    project_summary &&
                    <section className='project-summary'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12 text-center mb-5 pb-3'>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <h2 className='text-light-grey mb-3 pb-1'>{projectsumHeadingmain}</h2>
                                    </Motion>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2}} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <p className='text-light-grey'>{projectsumPara}</p>
                                    </Motion>
                                </div>
                                <div className='col-12 '>
                                    <div className=''>
                                        <img src={sumImg1} className={sumImg1 === "" ? 'w-100' : "w-100 project-imgs"} alt="" />
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div>
                                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                            <h3 className='casedetail-project-heading'>{projectsumHeading1}</h3>
                                        </Motion>
                                        <img src={sumImg2} className={sumImg2 === "" ? 'w-100' : "w-100 project-imgs"} alt="" />
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className=''>
                                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                            <h3 className='casedetail-project-heading'>{projectsumHeading2}</h3>
                                        </Motion>
                                        <img src={sumImg3} className={sumImg3 === "" ? 'w-100' : "w-100 project-imgs"} alt="" />
                                    </div>
                                    <div className=''>
                                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                            <h3 className='casedetail-project-heading'>{projectsumHeading3}</h3>
                                        </Motion>
                                        <img src={sumImg4} className={sumImg4 === "" ? 'w-100' : "w-100 project-imgs"} alt="" />
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div>
                                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                            <h3 className='casedetail-project-heading text-center'>{projectsumHeading4}</h3>
                                        </Motion>
                                        <img src={sumImg5} className={sumImg5 === "" ? 'w-100' : "w-100 project-imgs"} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </>
        }

        {
            mob_project &&
            <section className='clientWork'>
                <div className='container'>
                    <div className='col-12 text-center mb-5 pb-3'>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <h2 className='text-light-grey mb-3 pb-1'>Client Work</h2>
                        </Motion>
                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <p className='text-light-grey'>{clientwork_para}</p>
                        </Motion>
                    </div>
                    <div className='color-pallete'>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <h3 className='mb-4 pb-2'>Color Palette & Texture</h3>
                        </Motion>
                        <div className='d-flex justify-content-between align-items-center flex-wrap'>
                            {
                                color_pallete.map((curElem) =>{
                                    return(
                                        <Motion key={curElem.id} classNames="transition-none" transition={{ duration: 0.4, delay: curElem.id * .1 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                            <div className='color-boxes-main'>
                                                <div className={color1_Code === "#ffffff" || "#FFFFFF" ? 'border color-box' : 'color-box'} style={{ backgroundColor: `${curElem.color_Code}`}}></div>
                                                <p className='color-code'>{curElem.color_Code}</p>
                                            </div>
                                        </Motion>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='col-xl-10 mx-auto'>
                        <h3 className='text-light-grey mb-5'>Screens</h3>
                        <div className='row screen-views'>
                            {
                                screen_img.map((curElem) =>{
                                    return(
                                        <div className='col-lg-4 col-md-6 mb-4 pb-3' key={curElem.id}>
                                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: curElem.id * .1 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                                <img src={curElem.img} alt=''  className='w-100'/>
                                            </Motion>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        }

        {/* <section className='lets-chat'>
            <div className='container'>
                <div className='col-lg-10'>
                    <div className='d-flex gap-5 align-items-center flex-wrap flex-md-nowrap'>
                        <div>
                            <img src={require("../../assets/images/chatBig-icon.svg").default} alt=''></img>
                        </div>
                        <h2>Are you ready for WHAT’S NEXT? We ARE <NavLink to='/contactUs' className=''>Let’s Chat.</NavLink></h2>
                    </div>
                </div>
            </div>
        </section> */}
    </>
  )
}

export default CaseDetail
