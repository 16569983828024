// CountryDropdown.js
import React, { } from 'react';
import Select from 'react-select';

const CountryDropdown = () => {
  const countries = [
    { value: 'us', label: 'US' },
    { value: 'ca', label: 'CA' },
    { value: 'uk', label: 'UK' },
    { value: 'pk', label: 'PK' },
    { value: 'in', label: 'IN' },
    { value: 'ag', label: 'AG' },
    // Add more countries as needed
  ];

  return (

    <div className='country-dropdown-main'>
        <Select
            className='country-dropdown'
            defaultValue={countries[0]}
            options={countries}
            isSearchable={false}
            onChange={(selectedOption) => {
            console.log(selectedOption);
            }}
        />
    </div>
  );
};

export default CountryDropdown;
