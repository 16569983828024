import React from "react"
import  { NavLink, Link }  from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import ServicesDropdown from "./pages/ServicesDropdown"; 
import { ServiceglobelId } from "./app";

const Header = ({headerAbsolute}) => {

    const location = useLocation();

    const [showsubMenu, setshowsubMenu] = useState(false)
    function showmenu(){
        setshowsubMenu(!showsubMenu)
    }

    const [toggle, settoggle] = useState(false)

    function menuToggler(){
        settoggle(!toggle)
    }

    return(
        <>
        <ServiceglobelId.Consumer>
            {(value)=>{
                return (
                <>
                <div className="container-fluid position-relative">
                    <nav className={ location.pathname === `/services/${value.receivedServiceName}` || location.pathname === `/casedetail/${value.receivedcaseDetailId}`  ? "header-absolute navbar navbar-expand-xl navbar-light bg-transparent position-relative" : " navbar navbar-expand-xl navbar-light bg-transparent position-relative "}>
                        <Link className="navbar-brand" to="/"> <img src={require("../assets/images/header-logo.svg").default} alt="img"/></Link>
                        <Link className="navbar-brand-white" to="/"> <img src={require("../assets/images/header-logo-white.svg").default} alt="img"/></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={()=> menuToggler()}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={` ${toggle ? '' : 'collapse'} navbar-collapse justify-content-end flex-wrap text-center`} id="navbarSupportedContent">
                            <ul className="navbar-nav mb-2 mb-lg-0 align-items-center">
                                <li className="nav-item">
                                    <NavLink  className="nav-link" aria-current="page" to="/">Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink  className="nav-link" to="/about">About Us</NavLink>
                                </li>
                                <li className="nav-item dropdown" onClick={()=>showmenu()}>
                                    <NavLink  className="nav-link service-nav" id="" role="button"  to={`/services/${value.receivedServiceName}`}>Services 
                                        <span className="ms-1">
                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.8808 9.85742L12.8808 15.8574L18.8808 9.85742" stroke="current" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M6.8808 9.85742L12.8808 15.8574L18.8808 9.85742" stroke="current" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                    </NavLink>
                                    <ul className={showsubMenu ? "d-block dropdown-menu-content" : "dropdown-menu-content"} aria-labelledby="">
                                        <ServicesDropdown dropPos={"header"} />
                                    </ul>
                                    
                                </li>
                                <li className="nav-item">
                                    <NavLink  className="nav-link" to="/caseStudies">Case Studies</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink  className="nav-link" to="/contactUs">Contact Us</NavLink>
                                </li>
                                <li className="nav-item">
                                    <a href="tel:+92 (323) 8484458" className="nav-link number mb-0"><span className="d-xl-inline-block d-none">|</span> 
                                    <span className="ms-0 ms-xl-2">
                                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.28231 5.28027H10.949L13.2823 11.1082L10.3656 12.8566C11.6151 15.3877 13.6655 17.4362 16.199 18.6845L17.949 15.7706L23.7823 18.1017V22.7641C23.7823 24.0516 22.7376 25.0953 21.449 25.0953C12.0312 24.5235 4.5213 17.0205 3.94897 7.61145C3.94897 6.32398 4.99364 5.28027 6.28231 5.28027" stroke="#08213E" strokeWidth="2.04167" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M6.28231 5.28027H10.949L13.2823 11.1082L10.3656 12.8566C11.6151 15.3877 13.6655 17.4362 16.199 18.6845L17.949 15.7706L23.7823 18.1017V22.7641C23.7823 24.0516 22.7376 25.0953 21.449 25.0953C12.0312 24.5235 4.5213 17.0205 3.94897 7.61145C3.94897 6.32398 4.99364 5.28027 6.28231 5.28027" stroke="#08213E" strokeWidth="2.04167" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M17.949 6.59067C17.3852 6.59067 16.9281 7.04772 16.9281 7.61151C16.9281 8.1753 17.3852 8.63234 17.949 8.63234V6.59067ZM24.949 8.63234C25.5128 8.63234 25.9698 8.1753 25.9698 7.61151C25.9698 7.04772 25.5128 6.59067 24.949 6.59067V8.63234ZM22.4698 4.11475C22.4698 3.55096 22.0128 3.09391 21.449 3.09391C20.8852 3.09391 20.4281 3.55096 20.4281 4.11475H22.4698ZM20.4281 11.1083C20.4281 11.6721 20.8852 12.1291 21.449 12.1291C22.0128 12.1291 22.4698 11.6721 22.4698 11.1083H20.4281ZM17.949 8.63234H24.949V6.59067H17.949V8.63234ZM20.4281 4.11475V11.1083H22.4698V4.11475H20.4281Z" fill="#08213E"/>
                                        <path d="M17.949 6.59067C17.3852 6.59067 16.9281 7.04772 16.9281 7.61151C16.9281 8.1753 17.3852 8.63234 17.949 8.63234V6.59067ZM24.949 8.63234C25.5128 8.63234 25.9698 8.1753 25.9698 7.61151C25.9698 7.04772 25.5128 6.59067 24.949 6.59067V8.63234ZM22.4698 4.11475C22.4698 3.55096 22.0128 3.09391 21.449 3.09391C20.8852 3.09391 20.4281 3.55096 20.4281 4.11475H22.4698ZM20.4281 11.1083C20.4281 11.6721 20.8852 12.1291 21.449 12.1291C22.0128 12.1291 22.4698 11.6721 22.4698 11.1083H20.4281ZM17.949 8.63234H24.949V6.59067H17.949V8.63234ZM20.4281 4.11475V11.1083H22.4698V4.11475H20.4281Z" fill="#08213E"/>
                                    </svg>
                                    </span>
                                    92 (323) 8484458</a>
                                </li>
                            </ul>
                            <Link to="/contactUs" type="button" className="btn start-btn main-header-btn p-20px shadow-none">Request A Quote <span>
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.3333 8.00002H1.19114H15.3333Z" fill="#08213E"/>
                                    <path d="M15.3333 8.00002H1.19114" stroke="#08213E" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M15.3333 8.00002H1.19114" stroke="#08213E" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.96936 1.63599L15.3333 7.99995L8.96936 14.3639" stroke="#08213E" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.96936 1.63599L15.3333 7.99995L8.96936 14.3639" stroke="#08213E" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                </span></Link>
                        </div>
                    </nav>
                </div>
                </>
            )}}
        </ServiceglobelId.Consumer>
        </>
    );
};
export default Header