import React from "react";
import ScrollTop from "./animation/scrollTop";
// import {  Link} from "react-router-dom";
import ServicesDropdown from "./pages/ServicesDropdown";
import Motion from "./animation/Motion";
const Footer = () => {

    const date = new Date().getFullYear();
    
    return(
        <>
        <footer>
            <div className="footer-bg">
                <img src={require('../assets/images/footer-bg.svg').default} alt="" />
            </div>
            <div className="container-fluid z-index-2">
                <div className="row">
                    <div className="col-xl-3 footer-right mb-3 mb-xl-0">
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div>
                                <div className="footer-logo">
                                    <a href='/'>
                                        <img src={require("../assets/images/logo-new.svg").default} alt="" />
                                    </a>
                                </div>
                                <p className="font-sofia mt-4 mb-2 pb-4">Johar Town N, Block <br />
                                    <span className="d-block mt-1">Lahore, Pakistan</span>
                                </p>
                                <a href="mailto:info@zodiactechnologies.com" className="contect-p text-decoration-none text-dark mb-2">
                                    Email: <span className="ms-2">info@zodiactechnologies.com</span>
                                </a>
                                <a  href="tel:+92 (323) 8484458" className="contect-p text-decoration-none text-dark">
                                    Phone: <span className="text-nowrap ms-2">+92 (323) 8484458</span>
                                </a>
                            </div>
                        </Motion>
                    </div>
                    <div className="col-xl-9 footer-left px-0">
                        <div className="row">
                            <div className="col-md-6 col-lg-4 mb-3 mb-sm-0">
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className="footer-list-main services-list">
                                        <div className=" footer-list">
                                            <p className="">Services</p>
                                            <ul className="list-unstyled mb-0">
                                                <ServicesDropdown dropPos={"footer"}/>
                                            </ul>
                                        </div>
                                    </div>
                                </Motion>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-3 mb-sm-0">
                                <div className="footer-list-main list-icon">
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className=" footer-list">
                                            <p className="">Company</p>
                                            <ul className="list-unstyled">
                                                <li><a href="/" className="text-decoration-none ">Home </a></li>
                                                <li><a href="/about" className="text-decoration-none ">About Us </a></li>
                                                <li><a href="/caseStudies" className="text-decoration-none ">Case Studies </a></li>
                                                <li><a href="/contactUs" className="text-decoration-none ">Contact Us </a></li>
                                                <li><a href="/PrivacyPolicy" className="text-decoration-none ">Privacy Policy</a></li>
                                            </ul>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className="social-icon d-md-flex d-none">
                                            <span>
                                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.5438 32.0692L12.5 18.1803H6.94446V12.6248H12.5V9.15256C12.5 3.99784 15.6921 1.51367 20.2904 1.51367C22.4932 1.51367 24.3863 1.67766 24.9379 1.75096V7.13795L21.7488 7.13939C19.2478 7.13939 18.7636 8.32775 18.7636 10.0716V12.6248H26.0417L23.2639 18.1803H18.7636V32.0692H12.5438Z" fill="current"/>
                                                </svg>
                                            </span>
                                            <span>
                                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_31_681)">
                                                    <path d="M34 6.51378C32.75 7.06934 31.5 7.486 30.1111 7.62489C31.5 6.79156 32.6111 5.40267 33.1667 3.87489C31.7778 4.70822 30.3889 5.26378 28.8611 5.54156C27.6111 4.15267 25.8056 3.31934 23.8611 3.31934C20.1111 3.31934 17.0556 6.37489 17.0556 10.1249C17.0556 10.6804 17.0556 11.236 17.1945 11.6527C11.3611 11.3749 6.36113 8.59711 3.0278 4.43045C2.33335 5.54156 2.05558 6.65267 2.05558 7.90267C2.05558 10.2638 3.30558 12.3471 5.11113 13.5971C4.00002 13.5971 2.88891 13.3193 2.05558 12.7638C2.05558 12.7638 2.05558 12.7638 2.05558 12.9027C2.05558 16.236 4.41669 19.0138 7.47224 19.5693C6.91669 19.7082 6.36113 19.8471 5.66669 19.8471C5.25002 19.8471 4.83335 19.8471 4.41669 19.7082C5.25002 22.486 7.75002 24.4304 10.8056 24.4304C8.44446 26.236 5.5278 27.3471 2.33335 27.3471C1.7778 27.3471 1.22224 27.3471 0.666687 27.2082C3.72224 29.1527 7.33335 30.2638 11.0834 30.2638C23.7222 30.2638 30.5278 19.8471 30.5278 10.8193C30.5278 10.5416 30.5278 10.2638 30.5278 9.986C31.9167 9.01379 33.0278 7.76378 34 6.51378Z" fill="current"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_31_681">
                                                    <rect width="33.3333" height="33.3333" fill="white" transform="translate(0.666687 0.125)"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            <span>
                                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_31_684)">
                                                    <path d="M17 3.12778C21.45 3.12778 21.9778 3.14444 23.7348 3.225C25.632 3.31111 27.3917 3.69167 28.7459 5.04583C30.1 6.4 30.4806 8.15972 30.5667 10.0569C30.6473 11.8139 30.6639 12.3417 30.6639 16.7917C30.6639 21.2417 30.6473 21.7694 30.5667 23.5264C30.4806 25.4236 30.1 27.1833 28.7459 28.5375C27.3917 29.8917 25.632 30.2722 23.7348 30.3583C21.9778 30.4389 21.45 30.4556 17 30.4556C12.55 30.4556 12.0223 30.4389 10.2653 30.3583C8.3681 30.2722 6.60837 29.8917 5.25421 28.5375C3.90004 27.1833 3.51949 25.4236 3.43337 23.5264C3.35282 21.7694 3.33615 21.2417 3.33615 16.7917C3.33615 12.3417 3.35282 11.8139 3.43337 10.0569C3.51949 8.15972 3.90004 6.4 5.25421 5.04583C6.60837 3.69167 8.3681 3.31111 10.2653 3.225C12.0223 3.14444 12.55 3.12778 17 3.12778ZM17 0.125C12.4737 0.125 11.9056 0.144444 10.1278 0.225C7.41949 0.348611 5.04032 1.0125 3.1306 2.92222C1.22087 4.83194 0.556985 7.21111 0.433374 9.91944C0.352818 11.6972 0.333374 12.2653 0.333374 16.7917C0.333374 21.3181 0.352818 21.8861 0.433374 23.6639C0.556985 26.3722 1.22087 28.7514 3.1306 30.6611C5.04032 32.5708 7.41949 33.2347 10.1278 33.3583C11.9056 33.4389 12.4737 33.4583 17 33.4583C21.5264 33.4583 22.0945 33.4389 23.8723 33.3583C26.5806 33.2347 28.9598 32.5708 30.8695 30.6611C32.7792 28.7514 33.4431 26.3722 33.5667 23.6639C33.6473 21.8861 33.6667 21.3181 33.6667 16.7917C33.6667 12.2653 33.6473 11.6972 33.5667 9.91944C33.4431 7.21111 32.7792 4.83194 30.8695 2.92222C28.9598 1.0125 26.5806 0.348611 23.8723 0.225C22.0945 0.144444 21.5264 0.125 17 0.125Z" fill="current"/>
                                                    <path d="M16.9999 8.23291C12.2735 8.23291 8.44153 12.0649 8.44153 16.7913C8.44153 21.5176 12.2735 25.3496 16.9999 25.3496C21.7263 25.3496 25.5582 21.5176 25.5582 16.7913C25.5582 12.0649 21.7263 8.23291 16.9999 8.23291ZM16.9999 22.3468C13.9318 22.3468 11.4443 19.8593 11.4443 16.7913C11.4443 13.7232 13.9318 11.2357 16.9999 11.2357C20.0679 11.2357 22.5554 13.7232 22.5554 16.7913C22.5554 19.8593 20.0679 22.3468 16.9999 22.3468Z" fill="current"/>
                                                    <path d="M25.897 9.89453C27.0016 9.89453 27.897 8.9991 27.897 7.89453C27.897 6.78996 27.0016 5.89453 25.897 5.89453C24.7924 5.89453 23.897 6.78996 23.897 7.89453C23.897 8.9991 24.7924 9.89453 25.897 9.89453Z" fill="current"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_31_684">
                                                    <rect width="33.3333" height="33.3333" fill="current" transform="translate(0.333374 0.125)"/>
                                                    </clipPath>
                                                    </defs>
                                                    </svg>
                                            </span>
                                            <span>
                                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_31_689)">
                                                    <path d="M31.9444 0.125H1.38889C0.555556 0.125 0 0.680556 0 1.51389V32.0694C0 32.9028 0.555556 33.4583 1.38889 33.4583H31.9444C32.7778 33.4583 33.3333 32.9028 33.3333 32.0694V1.51389C33.3333 0.680556 32.7778 0.125 31.9444 0.125ZM9.86111 28.5972H5V12.625H10V28.5972H9.86111ZM7.36111 10.4028C5.83333 10.4028 4.44444 9.15278 4.44444 7.48611C4.44444 5.95833 5.69444 4.56944 7.36111 4.56944C8.88889 4.56944 10.2778 5.81944 10.2778 7.48611C10.2778 9.15278 9.02778 10.4028 7.36111 10.4028ZM28.4722 28.5972H23.4722V20.8194C23.4722 19.0139 23.4722 16.6528 20.9722 16.6528C18.3333 16.6528 18.0556 18.5972 18.0556 20.6806V28.5972H13.0556V12.625H17.7778V14.8472C18.4722 13.5972 20 12.3472 22.5 12.3472C27.5 12.3472 28.4722 15.6806 28.4722 19.9861V28.5972Z" fill="current"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_31_689">
                                                    <rect width="33.3333" height="33.3333" fill="current" transform="translate(0 0.125)"/>
                                                    </clipPath>
                                                    </defs>
                                                    </svg>
                                            </span>
                                        </div>
                                    </Motion>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-3 mb-sm-0">
                                <div className="footer-list-main footer-detail">
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className="">
                                            <p className="f-16px mb-5 pb-1 text-light-grey">
                                                Zodiac Technology is a full service software development company located in Johar Town N, Block Lahore, Pakistan.
                                            </p>
                                            <div>
                                                <p className="mb-4 pb-3 d-block">
                                                    <img src={require("../assets/images/image 159.svg").default} alt="img" />
                                                </p>
                                                <p>
                                                    <img src={require("../assets/images/image 160.svg").default} alt="img" />
                                                </p>
                                            </div>
                                            <div className="social-icon d-flex d-md-none mt-3">
                                                <span>
                                                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.5438 32.0692L12.5 18.1803H6.94446V12.6248H12.5V9.15256C12.5 3.99784 15.6921 1.51367 20.2904 1.51367C22.4932 1.51367 24.3863 1.67766 24.9379 1.75096V7.13795L21.7488 7.13939C19.2478 7.13939 18.7636 8.32775 18.7636 10.0716V12.6248H26.0417L23.2639 18.1803H18.7636V32.0692H12.5438Z" fill="current"/>
                                                    </svg>
                                                </span>
                                                <span>
                                                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_31_681)">
                                                        <path d="M34 6.51378C32.75 7.06934 31.5 7.486 30.1111 7.62489C31.5 6.79156 32.6111 5.40267 33.1667 3.87489C31.7778 4.70822 30.3889 5.26378 28.8611 5.54156C27.6111 4.15267 25.8056 3.31934 23.8611 3.31934C20.1111 3.31934 17.0556 6.37489 17.0556 10.1249C17.0556 10.6804 17.0556 11.236 17.1945 11.6527C11.3611 11.3749 6.36113 8.59711 3.0278 4.43045C2.33335 5.54156 2.05558 6.65267 2.05558 7.90267C2.05558 10.2638 3.30558 12.3471 5.11113 13.5971C4.00002 13.5971 2.88891 13.3193 2.05558 12.7638C2.05558 12.7638 2.05558 12.7638 2.05558 12.9027C2.05558 16.236 4.41669 19.0138 7.47224 19.5693C6.91669 19.7082 6.36113 19.8471 5.66669 19.8471C5.25002 19.8471 4.83335 19.8471 4.41669 19.7082C5.25002 22.486 7.75002 24.4304 10.8056 24.4304C8.44446 26.236 5.5278 27.3471 2.33335 27.3471C1.7778 27.3471 1.22224 27.3471 0.666687 27.2082C3.72224 29.1527 7.33335 30.2638 11.0834 30.2638C23.7222 30.2638 30.5278 19.8471 30.5278 10.8193C30.5278 10.5416 30.5278 10.2638 30.5278 9.986C31.9167 9.01379 33.0278 7.76378 34 6.51378Z" fill="current"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0_31_681">
                                                        <rect width="33.3333" height="33.3333" fill="white" transform="translate(0.666687 0.125)"/>
                                                        </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                                <span>
                                                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_31_684)">
                                                        <path d="M17 3.12778C21.45 3.12778 21.9778 3.14444 23.7348 3.225C25.632 3.31111 27.3917 3.69167 28.7459 5.04583C30.1 6.4 30.4806 8.15972 30.5667 10.0569C30.6473 11.8139 30.6639 12.3417 30.6639 16.7917C30.6639 21.2417 30.6473 21.7694 30.5667 23.5264C30.4806 25.4236 30.1 27.1833 28.7459 28.5375C27.3917 29.8917 25.632 30.2722 23.7348 30.3583C21.9778 30.4389 21.45 30.4556 17 30.4556C12.55 30.4556 12.0223 30.4389 10.2653 30.3583C8.3681 30.2722 6.60837 29.8917 5.25421 28.5375C3.90004 27.1833 3.51949 25.4236 3.43337 23.5264C3.35282 21.7694 3.33615 21.2417 3.33615 16.7917C3.33615 12.3417 3.35282 11.8139 3.43337 10.0569C3.51949 8.15972 3.90004 6.4 5.25421 5.04583C6.60837 3.69167 8.3681 3.31111 10.2653 3.225C12.0223 3.14444 12.55 3.12778 17 3.12778ZM17 0.125C12.4737 0.125 11.9056 0.144444 10.1278 0.225C7.41949 0.348611 5.04032 1.0125 3.1306 2.92222C1.22087 4.83194 0.556985 7.21111 0.433374 9.91944C0.352818 11.6972 0.333374 12.2653 0.333374 16.7917C0.333374 21.3181 0.352818 21.8861 0.433374 23.6639C0.556985 26.3722 1.22087 28.7514 3.1306 30.6611C5.04032 32.5708 7.41949 33.2347 10.1278 33.3583C11.9056 33.4389 12.4737 33.4583 17 33.4583C21.5264 33.4583 22.0945 33.4389 23.8723 33.3583C26.5806 33.2347 28.9598 32.5708 30.8695 30.6611C32.7792 28.7514 33.4431 26.3722 33.5667 23.6639C33.6473 21.8861 33.6667 21.3181 33.6667 16.7917C33.6667 12.2653 33.6473 11.6972 33.5667 9.91944C33.4431 7.21111 32.7792 4.83194 30.8695 2.92222C28.9598 1.0125 26.5806 0.348611 23.8723 0.225C22.0945 0.144444 21.5264 0.125 17 0.125Z" fill="current"/>
                                                        <path d="M16.9999 8.23291C12.2735 8.23291 8.44153 12.0649 8.44153 16.7913C8.44153 21.5176 12.2735 25.3496 16.9999 25.3496C21.7263 25.3496 25.5582 21.5176 25.5582 16.7913C25.5582 12.0649 21.7263 8.23291 16.9999 8.23291ZM16.9999 22.3468C13.9318 22.3468 11.4443 19.8593 11.4443 16.7913C11.4443 13.7232 13.9318 11.2357 16.9999 11.2357C20.0679 11.2357 22.5554 13.7232 22.5554 16.7913C22.5554 19.8593 20.0679 22.3468 16.9999 22.3468Z" fill="current"/>
                                                        <path d="M25.897 9.89453C27.0016 9.89453 27.897 8.9991 27.897 7.89453C27.897 6.78996 27.0016 5.89453 25.897 5.89453C24.7924 5.89453 23.897 6.78996 23.897 7.89453C23.897 8.9991 24.7924 9.89453 25.897 9.89453Z" fill="current"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0_31_684">
                                                        <rect width="33.3333" height="33.3333" fill="current" transform="translate(0.333374 0.125)"/>
                                                        </clipPath>
                                                        </defs>
                                                        </svg>
                                                </span>
                                                <span>
                                                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_31_689)">
                                                        <path d="M31.9444 0.125H1.38889C0.555556 0.125 0 0.680556 0 1.51389V32.0694C0 32.9028 0.555556 33.4583 1.38889 33.4583H31.9444C32.7778 33.4583 33.3333 32.9028 33.3333 32.0694V1.51389C33.3333 0.680556 32.7778 0.125 31.9444 0.125ZM9.86111 28.5972H5V12.625H10V28.5972H9.86111ZM7.36111 10.4028C5.83333 10.4028 4.44444 9.15278 4.44444 7.48611C4.44444 5.95833 5.69444 4.56944 7.36111 4.56944C8.88889 4.56944 10.2778 5.81944 10.2778 7.48611C10.2778 9.15278 9.02778 10.4028 7.36111 10.4028ZM28.4722 28.5972H23.4722V20.8194C23.4722 19.0139 23.4722 16.6528 20.9722 16.6528C18.3333 16.6528 18.0556 18.5972 18.0556 20.6806V28.5972H13.0556V12.625H17.7778V14.8472C18.4722 13.5972 20 12.3472 22.5 12.3472C27.5 12.3472 28.4722 15.6806 28.4722 19.9861V28.5972Z" fill="current"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0_31_689">
                                                        <rect width="33.3333" height="33.3333" fill="current" transform="translate(0 0.125)"/>
                                                        </clipPath>
                                                        </defs>
                                                        </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </Motion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container-fluid">
                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                        <div className="text-center">
                            <p className="mb-0 copyright">© 2021-{date} Zodiac Technology. All Rights Reserved.</p>
                        </div>
                    </Motion>
                    <div className="">
                        <ScrollTop />
                    </div>
                </div>
            </div>
        </footer>
        </>
    );
};
export default Footer