import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Projects from './projects';
import ProjectApi from '../api/projectsApi';
import logoApi from '../api/logoApi';
import Motion from '../animation/Motion';

const CaseStudies = () => {

    const sectionRef = useRef(null);
    
    const [projectdata, setprojectdata] = useState(ProjectApi)
    const [loading, setloading] = useState(true)
    
    // for load more btn
    const [visibleitem, setvisibleitem] = useState(9)
    const section = sectionRef.current;
    const loadmoreitem =() =>{
        if(section){
            const sectionBottom = section.offsetTop + section.clientHeight - window.innerHeight;
            if (window.pageYOffset >= sectionBottom ) {
                setvisibleitem(visibleitem < projectdata.length? visibleitem + 6 : visibleitem);
                if(visibleitem < projectdata.length){
                    setloading(true)
                }
            }
            else if(visibleitem > projectdata.length){
                setloading(false)
            }
            else{
                setloading(false)
            }
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', loadmoreitem);
        return () => {
          window.removeEventListener('scroll', loadmoreitem);
            setloading(false)
        };
      });
    
    // category tabs
    const [active, setactive] = useState(ProjectApi)

    const filterCategory = (Category) =>{
        
        const updatedList = ProjectApi.filter((curElem) =>{
            return curElem.category === Category;
        })
        setprojectdata(updatedList)
        setactive(Category)
    }

     const allCategory = () =>{
        setprojectdata(ProjectApi)
        setactive(ProjectApi)
     }

    //  for modal hide and show
     const [showModal , setshowModal] = useState(false)

     function modalshow(data){
        setshowModal(data)
     }
     const [returnID , setreturnID]  =  useState()

     function cardid(id){
        const recivedId = id
    	setreturnID(recivedId)
     }

    // for logo modal id match

    const [sliderdata, setsliderdata] = useState(0)
    const [btnindex, setbtnindex] = useState(0)

    const filteredLogo = logoApi.filter(item => item.id === returnID);

    const previedimg = filteredLogo.length > 0 ? (
        filteredLogo[0].previewImages.map((curElem, i) => (
        <img src={curElem.logo_img} key={i} className={sliderdata === i? "active": ""}  onClick={()=>viewfunc(i)} alt='img'/>
        ))
    ) :  ''

    function viewfunc(index){
        setsliderdata(index)
    }
     
    const handleNext = () => {
        let index = btnindex < previedimg.length -1  ? btnindex + 1 : 0 
        setbtnindex(index);
        setsliderdata(index);
      };
      
      const handlePrev = () => {
        let index = btnindex <= previedimg.length -1 && btnindex > 0  ? btnindex - 1 : btnindex 
        setbtnindex(index);
        setsliderdata(index);
      };

  return (
    <>
        <section className="project">
            <div className="container-fluid">
                <div className="main-heading mb-5 pb-3">
                    <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                        <h2 className='text-light-grey'>Crafted With Love.<br />
                            Built With Passion
                        </h2>
                        <div className="col-md-7">
                            <p className=" f-18px">offers transformative technology and software solutions to startups, global brands & Fortune 500 companies. Some of our major clients include IKEA, Domino's, Adidas, KPMG, BCG & Pizza Hut.</p>
                        </div>
                    </Motion>
                </div>
                <div className='mx-auto'>
                    <div className='project-category-menu'>
                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .3 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <ul className='list-unstyled d-flex justify-content-between flex-wrap flex-sm-nowrap'>
                                <li><p className={active === ProjectApi ? 'active' : "category-menu text-decoration-none "}  onClick={()=> allCategory()}>All Project</p> </li>
                                <li><p className={active === 'WebDevelopment' ? 'active' : "category-menu text-decoration-none "} onClick={()=> filterCategory('WebDevelopment')}>Web Development</p></li>
                                <li><p className={active === 'UI/UX' ? 'active' : "category-menu text-decoration-none "} onClick={()=> filterCategory('UI/UX')}>UX/UI Design</p></li>
                                <li><p className={active === 'LogoDesign' ? 'active' : "category-menu text-decoration-none "} onClick={()=> filterCategory('LogoDesign')}>Logo Design</p></li>
                                <li><p className={active === 'MobileApp' ? 'active' : "category-menu text-decoration-none "} onClick={()=> filterCategory('MobileApp')}>Mobile App</p></li>
                            </ul>
                        </Motion>
                    </div>
                </div>

                <div className="row mainProjects justify-content-center justify-content-sm-start" ref={sectionRef}>
                        <Projects projectdata={projectdata} visibleitem={visibleitem} filterCategory={filterCategory} modalshow={modalshow} cardid={cardid}/>
                </div>
                {
                    loading &&
                    <div className='col-12 d-flex justify-content-center align-items-center'>
                        <span className='border border-5 rounded-circle bg-transparent w-48 h-48 loading-circle'></span>
                    </div>
                }
                {/* <div className="text-center">
                    {
                    visibleitem >= projectdata.length ?  '' : <button className="btn shadow-none my-5 pt-3" onClick={loadmoreitem}><span className="me-2"><img src={require("../../assets/images/load-icon.svg").default} alt="" /></span>Load More</button>
                    }
                </div> */}
                { showModal && 
                    <div className=''>
                        <div className='modalBackround'></div>
                        <div className='modalMain logo'>
                            <div className='logo-modal'>
                                <div className='container-fluid'>
                                    <button className='modalclose' onClick={()=> setshowModal(false)}><img src={require("../../assets/images/close-icon.svg").default} alt='img' /></button>
                                    <div className='d-flex gap-3 justify-content-between align-items-center'>
                                        <div className='w-auto'>
                                            <button className='btn modal-nav' onClick={handlePrev}><img src={require('../../assets/images/arrow-narrow-left.svg').default} alt='img'/></button>
                                        </div>                                    
                                        <div className='main-img'>
                                            {previedimg[sliderdata]}
                                        </div>                                    
                                        <div className='w-auto'>
                                            <button className='btn modal-nav' onClick={handleNext}><img src={require('../../assets/images/arrow-narrow-right.svg').default} alt='img'/></button>
                                        </div>                                    
                                    </div>
                                    <div className='logo-image-preview'>
                                        {previedimg}
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </section> 
    </>
  )
}

export default CaseStudies
