import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ServiceApi from '../api/serviceApi'
import ContactSection from './ContactSection';
import Motion from '../animation/Motion';

const Services = ({ onServiceIdChange }) => {
    
    const  { serviceId, servicename } = useParams();
    const serviceid =  ServiceApi.find((curElem) => curElem.serviceName === servicename);
    // destructuring
    const {bannerbg_img, heading, banner_para, serviceDetail_heading, serviceDetail_img, serviceDetail_headingBlue, serviceDetail_para,
        detailCard_heading, detailCard_para,detailCard_heading2, detailCard_para2,detailCard_heading3, detailCard_para3,moreDetail_card,
        detailCard_heading4, detailCard_para4,detailCard_heading5, detailCard_para5,detailCard_heading6, detailCard_para6, cardList_format,
        detailCard1_list1,detailCard1_list2, detailCard1_list3,detailCard2_list1, detailCard2_list2, detailCard2_list3, detailCard3_list1, 
        detailCard3_list2, detailCard3_list3, awardWining_section, process_setion, award_heading, award_para, ideaReality_section, procces_img, process_heading, process_headingBlue, process_card1_heading, process_card2_heading, process_card3_heading, process_card4_heading,process_card5_heading, process_card6_heading, process_card1_list1,process_card1_list2,process_card1_list3,process_card1_list4,process_card2_list1,process_card2_list2,process_card2_list3,process_card2_list4,process_card3_list1,process_card3_list2,process_card3_list3,process_card3_list4,process_card4_list1,process_card4_list2,process_card4_list3,process_card4_list4,process_card5_list1,process_card5_list2,process_card5_list3,process_card5_list4,process_card6_list1,process_card6_list2,process_card6_list3, process_card6_list4, shipment_section, flexible, strategy, mobile_app_img, softwareShip_img, serviceName, award_bg, flexible_bg, ideaReality_bg, dev_portfolio,tech_weUse, tech_weUse_bg, tech_weUse_white, dev_count, our_deed, ecommerce_success, custom_solutions, emerging_tech, blockchain_product,zodiac_blockchain, blockchain_dev_opt, service_spec,cloud_page, web_working_process, banner_direction} = serviceid
    
    useEffect(()=>{
        onServiceIdChange(serviceId,servicename);
    }, [serviceId,servicename, onServiceIdChange])

    const[activeTab, setactiveTab] = useState(0)

    const handleactiveClick = (index) => {
        setactiveTab(index)
    }
  return (
      <>
        <section className="services-banner" style={{ backgroundImage: `url(${bannerbg_img})`}}>
            <div className="container-fluid">
                <div className='servce-banner-content'>
                    <div className={`servce-banner-content-inner  ${banner_direction}`}>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <h2 className="services-banner-heading text-white">{heading}</h2>
                        </Motion>
                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <p>{banner_para}</p>
                        </Motion>
                    </div>
                </div>
            </div>
        </section>

        {
            dev_count &&
            <section className='dev-count'>
                <div className='container'>
                    <div className='col-lg-10 mx-auto'>
                        <div className='row'>
                            <div className='col-md-4 col-sm-6 mb-3'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='count-boxes'>
                                        <h2>20+</h2>
                                        <p>Developers In-House</p>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-md-4 col-sm-6 mb-3'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='count-boxes'>
                                        <h2>150+</h2>
                                        <p>Software Projects Developed</p>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-md-4 col-sm-6 mb-3'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='count-boxes'>
                                        <h2>25+</h2>
                                        <p>Industries Brought on Software Dev</p>
                                    </div>
                                </Motion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }

        {
            blockchain_dev_opt &&
            <section className='blockchain-dev-opt'>
                <div className='container-fluid'>
                    <div className='dev-opt-mian'>
                        <div className='row'>
                            <div className='col-lg-3 col-sm-6'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='text-center'>
                                        <img alt='img' src={require('../../assets/images/website-designing 1.svg').default}/>
                                        <h4>DeFi Development</h4>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-lg-3 col-sm-6'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='text-center'>
                                        <img alt='img' src={require('../../assets/images/website-development 1.svg').default} />
                                        <h4>DAPPs Development</h4>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-lg-3 col-sm-6'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='text-center'>
                                        <img alt='img' src={require('../../assets/images/e-commerce 1.svg').default} />
                                        <h4>Crypto Token Development</h4>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-lg-3 col-sm-6'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='text-center'>
                                        <img alt='img' src={require('../../assets/images/e-commerce 1.svg').default} />
                                        <h4>ERC20 Token Development</h4>
                                    </div>
                                </Motion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }

        {
            service_spec &&
            <section className='service-spec'>
                <div className='container-fluid'>
                    <div className='row mb-sm-5 mb-0 pb-3 align-items-center'>
                        <div className='col-lg-6 col-md-8 mx-auto mb-3 mb-lg-0'>
                            <div className='services-z-img'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <img src={serviceDetail_img} alt='img'></img>
                                </Motion>
                            </div>
                        </div>
                        <div className='col-lg-6 mb-5 pb-4'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <h2 className='mb-5 text-light-grey'>{serviceDetail_heading}</h2>
                                <h4 className='text-light-blue mb-5'>{serviceDetail_headingBlue}</h4>
                            </Motion>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <p className='mb-0'>{serviceDetail_para}</p>
                            </Motion>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xl-4 col-md-6 mb-3 mb-xl-0'>
                            <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='service-spec-box'>
                                    <div className='mb-4 pb-1'>
                                        <img alt='img' src={require('../../assets/images/taget-people.svg').default}></img>
                                    </div>
                                    <h4>{detailCard_heading}</h4>
                                    <p>{detailCard_para}</p>
                                    {
                                        cardList_format &&
                                        <ul>
                                            <li className={detailCard1_list1 === "" ? 'd-none' : ""}>{detailCard1_list1}</li>
                                            <li className={detailCard1_list2 === "" ? 'd-none' : ""}>{detailCard1_list2}</li>
                                            <li className={detailCard1_list3 === "" ? 'd-none' : ""}>{detailCard1_list3}</li>
                                        </ul>
                                    }
                                </div>
                            </Motion>
                        </div>
                        <div className='col-xl-4 col-md-6 mb-3 mb-xl-0'>
                            <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='service-spec-box'>
                                    <div className='mb-4 pb-1'>
                                        <img alt='img' src={require('../../assets/images/chat-stats.svg').default}></img>
                                    </div>
                                    <h4>{detailCard_heading2}</h4>
                                    <p>{detailCard_para2}</p>
                                    {
                                        cardList_format &&
                                        <ul>
                                            <li className={detailCard2_list1 === "" ? 'd-none' : ""}>{detailCard2_list1}</li>
                                            <li className={detailCard2_list2 === "" ? 'd-none' : ""}>{detailCard2_list2}</li>
                                            <li className={detailCard2_list3 === "" ? 'd-none' : ""}>{detailCard2_list3}</li>
                                        </ul>
                                    }
                                </div>
                            </Motion>
                        </div>
                        <div className='col-xl-4 col-md-6 mb-3 mb-xl-0'>
                            <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='service-spec-box'>
                                    <div className='mb-4 pb-1'>
                                        <img alt='img' src={require('../../assets/images/paint-brush.svg').default}></img>
                                    </div>
                                    <h4>{detailCard_heading3}</h4>
                                    <p>{detailCard_para3}</p>
                                    {
                                        cardList_format &&
                                        <ul>
                                            <li className={detailCard3_list1 === "" ? 'd-none' : ""}>{detailCard3_list1}</li>
                                            <li className={detailCard3_list2 === "" ? 'd-none' : ""}>{detailCard3_list2}</li>
                                            <li className={detailCard3_list3 === "" ? 'd-none' : ""}>{detailCard3_list3}</li>
                                        </ul>
                                    }
                                </div>
                            </Motion>
                        </div>
                    </div>
                    {
                        moreDetail_card &&
                        <div className='row mt-4 pt-3'>
                            <div className='col-xl-4 col-md-6 mb-3 mb-xl-0'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='service-spec-box'>
                                        <div className='mb-4 pb-1'>
                                            <img alt='img' src={require('../../assets/images/taget-people.svg').default}></img>
                                        </div>
                                        <h4>{detailCard_heading4}</h4>
                                        <p>{detailCard_para4}</p>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-xl-4 col-md-6 mb-3 mb-xl-0'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: 1 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='service-spec-box'>
                                        <div className='mb-4 pb-1'>
                                            <img alt='img' src={require('../../assets/images/chat-stats.svg').default}></img>
                                        </div>
                                        <h4>{detailCard_heading5}</h4>
                                        <p>{detailCard_para5}</p>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-xl-4 col-md-6 mb-3 mb-xl-0'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: 1 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='service-spec-box'>
                                        <div className='mb-4 pb-1'>
                                            <img alt='img' src={require('../../assets/images/paint-brush.svg').default}></img>
                                        </div>
                                        <h4>{detailCard_heading6}</h4>
                                        <p>{detailCard_para6}</p>
                                    </div>
                                </Motion>
                            </div>
                        </div>
                    }
                    <div></div>
                    <div></div>
                </div>
            </section>
        }

        {
            cloud_page &&
            <>
                <section className='why-zodiac'>
                    <div className='container-fluid'>
                        <div className='col-lg-10 mx-auto'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <h2 className='text-light-grey text-center mb-5 pb-2 font-inter-b'>Why businesses choose Zodiac</h2>
                            </Motion>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='text-center'>
                                            <h2 className='text-light-blue mb-2'>60%</h2>
                                            <p className='fw-semibold text-black'>75% increase in <br /> organic traffic Yr on Yr.</p>
                                        </div>
                                    </Motion>
                                </div>
                                <div className='col-lg-4'>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='text-center'>
                                            <h2 className='text-light-blue mb-2'>90%</h2>
                                            <p className='fw-semibold text-black'>Retention <br /> Rate</p>
                                        </div>
                                    </Motion>
                                </div>
                                <div className='col-lg-4'>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='text-center'>
                                            <h2 className='text-light-blue mb-2'>150+</h2>
                                            <p className='fw-semibold text-black'>Our <br /> Engineers</p>
                                        </div>
                                    </Motion>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='cloud-services'>
                    <div className='container-fluid'>
                        <div className='mb-5 pb-2'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <h2 className='text-white text-center'>Our Cloud Services</h2>
                            </Motion>
                        </div>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 text-center p-0'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-boxes one pt-0'>
                                        <img alt='img' src={require('../../assets/images/cloud-infrastructure 1.svg').default} />
                                        <h4>Cloud Infrastructure Setup & Management</h4>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-lg-4 col-md-6 text-center p-0'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-boxes two pt-0'>
                                        <img alt='img' src={require('../../assets/images/cloud-migration 1.svg').default} />
                                        <h4>Cloud Migration <br /> Services</h4>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-lg-4 col-md-6 text-center p-0'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-boxes three pt-0'>
                                        <img alt='img' src={require('../../assets/images/cloud-application 1.svg').default} />
                                        <h4>Cloud Application Development</h4>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-lg-4 col-md-6 text-center p-0'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-boxes four'>
                                        <img alt='img' src={require('../../assets/images/cloud-security 1.svg').default} />
                                        <h4>Cloud Security and Compliance</h4>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-lg-4 col-md-6 text-center p-0'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-boxes five'>
                                        <img alt='img' src={require('../../assets/images/cloud-computing (1) 1.svg').default} />
                                        <h4>Serverless <br /> Architecture</h4>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-lg-4 col-md-6 text-center p-0'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-boxes six'>
                                        <img alt='img' src={require('../../assets/images/scalability 1.svg').default} />
                                        <h4>Scalability & Performance Optimization</h4>
                                    </div>
                                </Motion>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='cloud-tech'>
                    <div className='container-fluid'>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className='row mb-5'>
                                <div className='col-lg-6'>
                                    <h2 className='text-light-grey font-inter-sb'>Tools and Technologies We Use</h2>
                                </div>
                                <div className='col-lg-6'>
                                    <p className='font-sofia'>We utilize AWS, Google Cloud, Azure, IBM Cloud, Docker, Kubernetes, Terraform, and VMware to deliver top-notch, reliable, and secure cloud services.</p>
                                </div>
                            </div>
                        </Motion>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-4 col-md-6 mb-4'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-tech-card'>
                                        <div>
                                            <h4>Cloud Providers</h4>
                                            <p>Amazon AWS, GCP, Microsoft Azure, Any Private Cloud and other…</p>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/Amazon-AWS.svg.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/Group-581739.svg.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/Group-581740.svg.svg').default} />
                                            </div>
                                        </div>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-md-6 mb-4'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-tech-card'>
                                        <div>
                                            <h4>Containers & Orchestration</h4>
                                            <p>Docker, Compose, Kubernetes and other…</p>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/docker.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/Picture → Nosql.png.webp.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/Group-581742.svg.svg').default} />
                                            </div>
                                        </div>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-md-6 mb-4'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-tech-card'>
                                        <div>
                                            <h4>CI/CD</h4>
                                            <p>Jenkins, CitLab, GitHub, Teamcity, CircleCI, Travis CI, Bitbucket pipelines, DroneCI, Flux, ArgoCD and other…</p>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/gitlab.svg.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/git.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/tc.svg').default} />
                                            </div>
                                        </div>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-md-6 mb-4'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-tech-card'>
                                        <div>
                                            <h4>Configuration management</h4>
                                            <p>Ansible, Chef, Puppet and other...</p>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/Ansible_logo.svg.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/Chef_logo.svg.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/Puppet_transparent_logo.svg.svg').default} />
                                            </div>
                                        </div>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-md-6 mb-4'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-tech-card'>
                                        <div>
                                            <h4>Databases</h4>
                                            <p>MySQL, MongoDB, Amazon Aurora, PostgresSQL, Percona, Scylla DB, Clickhouse MariaDB, Oracle, MS SQL, InnoDB and other…</p>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/my-sql.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/p-sql.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/p-sql.svg').default} />
                                            </div>
                                        </div>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-md-6 mb-4'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-tech-card'>
                                        <div>
                                            <h4>Service</h4>
                                            <p>RabbitMQ, Apache Kafka, ApacheCassandra, Redis, ELK stack, Istio, MinIO, Memcached, Kiali and other…</p>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/rabbitmq.svg.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/Apache_kafka.svg.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/redis.svg.svg').default} />
                                            </div>
                                        </div>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-md-6 mb-4'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-tech-card'>
                                        <div>
                                            <h4>Monitoring</h4>
                                            <p>Prometheus, Datadog, Sentry, Grafana, PagerDuty, InfluxDB, Azure Monitor, Google Stackdriver, Amazon Cloudwatch and other…</p>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/fire-logo.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/Datadog.svg.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/Picture → SVN-1.png.webp.svg').default} />
                                            </div>
                                        </div>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-md-6 mb-4'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-tech-card'>
                                        <div>
                                            <h4>Infrastructure provisioning</h4>
                                            <p>Terraform, Pulumi, AWS CloudFormation and other…</p>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/terra-logo.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/Picture → Nosql-4.png.webp.svg').default} />
                                            </div>
                                            <div className='cloud-card-img'>
                                                <img alt='img' src={require('../../assets/images/Picture → Nosql-6.png.webp.svg').default} />
                                            </div>
                                        </div>
                                    </div>
                                </Motion>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='cloud-benefit'>
                    <div className='container-fluid'>
                        <div className='mb-5'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <h2 className='text-white'>Benefits of Cloud Solutions</h2>
                            </Motion>
                        </div>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-4 col-md-6 mb-3'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-benifit-boxes'>
                                        <h4>Access to professional DevOps engineers</h4>
                                        <p>Hiring our DevOps team, you get quick and easy access to experienced DevOps engineers, who are able to power your project with their expertise right from the start.</p>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-md-6 mb-3'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-benifit-boxes'>
                                        <h4>Cost <br/> Reduction</h4>
                                        <p>Due to improved workflows, you cut unnecessary expenses. Additionally, we help SaaS businesses significantly reduce cloud costs and take them under control.</p>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-md-6 mb-3'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-benifit-boxes'>
                                        <h4>Improved operational  support</h4>
                                        <p>DevOps as a Services simplifies the data and information flow of a project for more convenient collaboration. You also get better documentation and quality control.</p>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-xl-3 col-lg-4 col-md-6 mb-3'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='cloud-benifit-boxes'>
                                        <h4>Increased development team agility</h4>
                                        <p>As a result, companies notice happier and more engaged teams, faster speed and lower cost of releases. Developers obtain more data storage and computing power.</p>
                                    </div>
                                </Motion>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        }

        <section className="working-with m-0 services-page-working trust-leading">
            <div className="">
                <div className="">
                    <div className='text-center mb-5 mx-auto leading-brand pb-5'>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <h2 className='text-light-grey'>Trusted by Leading Brands</h2>
                        </Motion>
                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <h4 className='text-light-blue'>A truly different mobile apps development company.</h4>
                        </Motion>
                    </div>
                    <div className=""> 
                        <OwlCarousel  items= {4.5} margin={80} loop= {true} autoplay ={true} nav ={false} dots= {false} autoplayTimeout= {3000}autoplayHoverPause= {false} autoplaySpeed={2000}
                            responsive={ {'1':{items:2}, '500':{items:2}, '600':{items:3},'1200':{items:4.5}}} id='working-slides'>
                            <div className="item">
                                <div>
                                    <img src={require("../../assets/images/clutch.svg").default} alt="" />
                                </div>
                            </div>
                            <div className="item">
                                <div>
                                    <img src={require("../../assets/images/whompe.svg").default} alt="" />
                                </div>
                            </div>
                            <div className="item">
                                <div>
                                    <img src={require("../../assets/images/goodFirm.svg").default} alt="" />
                                </div>
                            </div>
                            <div className="item">
                                <div>
                                    <img src={require("../../assets/images/octalogix.svg").default} alt="" />
                                </div>
                            </div>
                            <div className="item">
                                <div>
                                    <img src={require("../../assets/images/image 93.svg").default} alt="" />
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>

        {
            cloud_page &&
            
            <section className="products services-product">
                <div className="container-fluid">
                    <div className="main-heading">
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <h2 className="mb-5 pb-4 text-light-grey">Our Working Process for Cloud <br />  Native Development</h2>
                        </Motion>
                        <div className="product-graph row">
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-xl-0 mb-3'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='product-img'>
                                        <img alt='img' src={require('../../assets/images/infrastructure 1.svg').default} />
                                        <div className='product-img-num'>1</div>
                                    </div>
                                    <p className='mt-5 mb-0 text-light-grey fw-semibold text-center'>Microservices Architecture</p>
                                </Motion>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-xl-0 mb-3'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='product-img'>
                                        <img alt='img' src={require('../../assets/images/devops (1) 1.svg').default} />
                                        <div className='product-img-num'>2</div>
                                    </div>
                                    <p className='mt-5 mb-0 text-light-grey fw-semibold text-center'>DevOps <br/> Practices</p>
                                </Motion>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-xl-0 mb-3'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='product-img'>
                                        <img alt='img' src={require('../../assets/images/cloud-computing (2) 1.svg').default} />
                                        <div className='product-img-num'>3</div>
                                    </div>
                                    <p className='mt-5 mb-0 text-light-grey fw-semibold text-center'>Serverless Computing</p>
                                </Motion>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-xl-0 mb-3'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='product-img'>
                                        <img alt='img' src={require('../../assets/images/resilience 1.svg').default} />
                                        <div className='product-img-num'>4</div>
                                    </div>
                                    <p className='mt-5 mb-0 text-light-grey fw-semibold text-center'>Resilience and Fault Tolerance</p>
                                </Motion>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-xl-0 mb-3'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='product-img'>
                                        <img alt='img' src={require('../../assets/images/monitoring 1.svg').default} />
                                        <div className='product-img-num'>5</div>
                                    </div>
                                    <p className='mt-5 mb-0 text-light-grey fw-semibold text-center'>Monitoring and Observability</p>
                                </Motion>
                            </div>
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-xl-0 mb-3'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='product-img'>
                                        <img alt='img' src={require('../../assets/images/crate 1.svg').default} />
                                        <div className='product-img-num'>6</div>
                                    </div>
                                    <p className='mt-5 mb-0 text-light-grey fw-semibold text-center'>Containers</p>
                                </Motion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }

        {
            zodiac_blockchain &&
            <>
                <section className='zodiac-blockchain'>
                    <div className='d-flex align-items-center flex-wrap flex-lg-nowrap justify-content-center justify-content-lg-between'>
                        <div className='col-lg-4 col-md-8'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <img className='w-100' src={require('../../assets/images/laptop-mob.png')} alt='img'/>
                            </Motion>
                        </div>
                        <div className='col-lg-7'>
                            <div className='container'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <h2 className='text-light-grey mb-4'>Why Zodiac for Blockchain <br /> Development?</h2>
                                    <p className='mb-4 pb-2 text-light-blue'>We convert your ideas into practical applications that are scalable and reliable. </p>
                                </Motion>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <p>Our blockchain developers follow a sound process to test quickly and get product maturity at an early stage. With the experience  of <span className='text-bold'>building 50+ blockchain-powered solutions</span>, we have tailored our process to align with the constant evolution of blockchain technology.</p>
                                </Motion>
                                <div className='row mt-5 pt-3'>
                                    <div className='col-md-4 col-sm-6'>
                                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                            <img alt='img' src={require('../../assets/images/Group 156.svg').default} />
                                            <h4>Dynamic Engagement<br/> Model</h4>
                                        </Motion>
                                    </div>
                                    <div className='col-md-4 col-sm-6'>
                                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                            <img alt='img' src={require('../../assets/images/team-search 1.svg').default} />
                                            <h4>Center of Excellence<br/> and Research Team</h4>
                                        </Motion>
                                    </div>
                                    <div className='col-md-4 col-sm-6'>
                                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                            <img alt='img' src={require('../../assets/images/technologies-services-provider 1.svg').default} />
                                            <h4>Use of Latest<br/> Technologies</h4>
                                        </Motion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='work-tech'>
                    <div className='container-fluid'>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <h2 className='text-white mb-5'>We Work on Different Technology</h2>
                        </Motion>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4 , delay: .2}} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='d-flex align-items-center mb-5 pb-4'>
                                        <h4>Front End </h4>
                                        <span className='ms-2'><img alt='img' src={require('../../assets/images/arrow-down.svg').default} /></span>
                                    </div>
                                    <div className='frontend-box'>
                                        <span><img alt='img' src={require('../../assets/images/image 10.svg').default} /></span>
                                        <div>
                                            <h3>Angular -</h3>
                                            <p>Client Side Code <br/> Accessible Via Browser</p>
                                        </div>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-lg-8'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4,delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='d-flex align-items-center mb-5 pb-4'>
                                        <h4>Back End </h4>
                                        <span className='ms-2'><img alt='img' src={require('../../assets/images/arrow-down.svg').default} /></span>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center flex-wrap flex-sm-nowrap'>
                                        <div className='frontend-box'>
                                            <span><img alt='img' src={require('../../assets/images/image 12.svg').default} /></span>
                                            <div>
                                                <h3>Node.js -</h3>
                                                <p>Runtime <br/> Environment</p>
                                            </div>
                                        </div>
                                        <div className='frontend-box'>
                                            <span><img alt='img' src={require('../../assets/images/pngegg (1) 1.svg').default} /></span>
                                            <div>
                                                <h3>Express -</h3>
                                                <p>Web Application <br/> Framework</p>
                                            </div>
                                        </div>
                                        <div className='frontend-box'>
                                            <span><img alt='img' src={require('../../assets/images/image 37.svg').default} /></span>
                                            <div>
                                                <h3>MongoDB -</h3>
                                                <p>Document<br/> Database</p>
                                            </div>
                                        </div>
                                    </div>
                                </Motion>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        }

        {
            our_deed &&
            <section className='need-deed'>
                <div className='container-fluid'>
                    <div className='col-lg-10 mx-auto text-center mb-5 pb-4'>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <h2>Custom Development Your Need Our Deed</h2>
                            <p>We transform your business needs into a unique, user-friendly, and high-performing website. Your vision, our expert execution.</p>
                        </Motion>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 mb-5'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='deed-cards'>
                                    <div>
                                        <img alt='img' src={require('../../assets/images/system-setting 1.svg').default} />
                                    </div>
                                    <h4>e-Commerce Development</h4>
                                    <p>Do you need an online store? We help to build eCommerce website for all business domains and make sure it benefits the market needs.</p>
                                </div>
                            </Motion>
                        </div>
                        <div className='col-lg-4 col-md-6 mb-5'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='deed-cards'>
                                    <div>
                                        <img alt='img' src={require('../../assets/images/mobility 1.svg').default} />
                                    </div>
                                    <h4>Mobility</h4>
                                    <p>We are renowned website application development firm with nearly 500+ satisfied client from all over the world in the particular technology.</p>
                                </div>
                            </Motion>
                        </div>
                        <div className='col-lg-4 col-md-6 mb-5'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='deed-cards'>
                                    <div>
                                        <img alt='img' src={require('../../assets/images/devops 1.svg').default} />
                                    </div>
                                    <h4>DevOps</h4>
                                    <p>Our excellent DevOps team ensures the the clouds are managed and function well. We have highly expertise team working on it.</p>
                                </div>
                            </Motion>
                        </div>
                        <div className='col-lg-4 col-md-6 mb-5'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='deed-cards'>
                                    <div>
                                        <img alt='img' src={require('../../assets/images/coding (2) 1.svg').default} />
                                    </div>
                                    <h4>Frontend Engineering</h4>
                                    <p>Do you need an online store? We help to build eCommerce website for all business domains and make sure it benefits the market needs.</p>
                                </div>
                            </Motion>
                        </div>
                        <div className='col-lg-4 col-md-6 mb-5'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='deed-cards'>
                                    <div>
                                        <img alt='img' src={require('../../assets/images/backend 1.svg').default} />
                                    </div>
                                    <h4>Backend Engineering</h4>
                                    <p>We are renowned website application development firm with nearly 500+ satisfied client from all over the world in the particular technology.</p>
                                </div>
                            </Motion>
                        </div>
                        <div className='col-lg-4 col-md-6 mb-5'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='deed-cards'>
                                    <div>
                                        <img alt='img' src={require('../../assets/images/mobile-app-development (1) 1.svg').default} />
                                    </div>
                                    <h4>Custom Web App Development</h4>
                                    <p>Our excellent DevOps team ensures the the clouds are managed and function well. We have highly expertise team working on it.</p>
                                </div>
                            </Motion>
                        </div>
                    </div>
                </div>
            </section>
        }

        {
            emerging_tech &&
            <section className='emerging-tech'>
                <div className='container-fluid'>
                    <div className='row justify-content-between'>
                        <div className='col-lg-6'>
                            <div>
                                <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <small className='fw-bold text-uppercase'>Powering Your Solutions With</small>
                                    <h2>Emerging Technologies</h2>
                                </Motion>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <p>Lorem ipsum dolor sit amet consectetur. Ornare enim volutpat ut scelerisque. Aenean id amet mattis dignissim dui. Morbi vulputate porttitor tellus tincidunt rhoncus sed ipsum. Dignissim eget egestas sed est congue. Dignissim fringilla nec in eget consequat purus. Turpis quisque odio egestas eget.</p>
                                </Motion>
                            </div>
                        </div>
                        <div className='col-xl-5 col-lg-6'>
                            <div className='logo-boxes-main'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='logo-boxes'>
                                        <img alt='img' src={require('../../assets/images/tech_icon1.png')} />
                                    </div>
                                </Motion>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='logo-boxes'>
                                        <img alt='img' src={require('../../assets/images/tech_icon2.png.svg').default} />
                                    </div>
                                </Motion>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='logo-boxes'>
                                        <img alt='img' src={require('../../assets/images/tech_icon3.png')} />
                                    </div>
                                </Motion>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='logo-boxes'>
                                        <img alt='img' src={require('../../assets/images/tech_icon4.png')} />
                                    </div>
                                </Motion>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='logo-boxes'>
                                        <img alt='img' src={require('../../assets/images/tech_icon5.png')} />
                                    </div>
                                </Motion>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='logo-boxes'>
                                        <img alt='img' src={require('../../assets/images/tech_icon6.png.svg').default} />
                                    </div>
                                </Motion>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='logo-boxes'>
                                        <img alt='img' src={require('../../assets/images/tech_icon7.png')} />
                                    </div>
                                </Motion>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='logo-boxes'>
                                        <img alt='img' src={require('../../assets/images/tech_icon8.png.svg').default} />
                                    </div>
                                </Motion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }

        {
            custom_solutions &&
            <section className='custom-solutions'>
                <div className='container-fluid'>
                    <div className='col-lg-8 mx-auto mb-5 pb-1 px-sm-4'>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <h2 className='text-light-grey text-center'>Our Custom Development Solutions For Different Industries</h2>
                        </Motion>
                    </div>
                    <div className='custom-solutions-boxes-main'>
                        <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className='custom-solutions-boxes'>
                                <div>
                                    <img alt='img' src={require('../../assets/images/fintech (2) 1.svg').default} />
                                </div>
                                <p>Fintech</p>
                            </div>
                        </Motion>
                        <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className='custom-solutions-boxes'>
                                <div>
                                    <img alt='img' src={require('../../assets/images/real-estate-support 1.svg').default} />
                                </div>
                                <p>Real Estate</p>
                            </div>
                        </Motion>
                        <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className='custom-solutions-boxes'>
                                <div>
                                    <img alt='img' src={require('../../assets/images/e-commerce (5) 1.svg').default} />
                                </div>
                                <p>E-Commerce</p>
                            </div>
                        </Motion>
                        <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className='custom-solutions-boxes'>
                                <div>
                                    <img alt='img' src={require('../../assets/images/healthcare (3) 1.svg').default} />
                                </div>
                                <p>Healthcare</p>
                            </div>
                        </Motion>
                        <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className='custom-solutions-boxes'>
                                <div>
                                    <img alt='img' src={require('../../assets/images/supply-chain (5) 1.svg').default} />
                                </div>
                                <p>Supply Chain</p>
                            </div>
                        </Motion>
                        <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className='custom-solutions-boxes'>
                                <div>
                                    <img alt='img' src={require('../../assets/images/logistics (2) 1.svg').default} />
                                </div>
                                <p>Logistics</p>
                            </div>
                        </Motion>
                        <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className='custom-solutions-boxes'>
                                <div>
                                    <img alt='img' src={require('../../assets/images/banking-fees 1.svg').default} />
                                </div>
                                <p>Banking</p>
                            </div>
                        </Motion>
                    </div>
                </div>
            </section>
        }

        {
            web_working_process &&
            <section className='web-working-process'>
                <div className='container-fluid'>
                    <div className=''>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <h2 className='text-white font-exo-b'>Our Web Development < br/> Working Process</h2>
                        </Motion>
                    </div>
                    <div className='web-process-count-main'>
                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className='web-process-count'>
                                <small>STEP</small>
                                <h2>01</h2>
                                <p>Blockchain <br className='d-block d-lg-none' /> Ideation</p>
                            </div>
                        </Motion>
                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className='web-process-count'>
                                <small>STEP</small>
                                <h2>02</h2>
                                <p>PoC <br className='d-block d-lg-none' /> Development</p>
                            </div>
                        </Motion>
                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className='web-process-count'>
                                <small>STEP</small>
                                <h2>03</h2>
                                <p>Technical Design & <br className='d-block d-lg-none' /> Development</p>
                            </div>
                        </Motion>
                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className='web-process-count'>
                                <small>STEP</small>
                                <h2>04</h2>
                                <p>Testing & <br className='d-block d-lg-none' /> Deployment</p>
                            </div>
                        </Motion>
                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: 1 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <div className='web-process-count'>
                                <small>STEP</small>
                                <h2>05</h2>
                                <p>Support & <br className='d-block d-lg-none' /> Upgrades</p>
                            </div>
                        </Motion>

                    </div>
                </div>
            </section>
        }

        {
            awardWining_section && 
            <section className='award-win' style={{ backgroundImage: `url(${award_bg})`}}>
                <div className='container'>
                    <div className='mx-auto text-center'>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <h2 className='text-white mb-4 pb-3 text-capitalize'>{award_heading}</h2>
                            <p className='mb-5 text-white'>{award_para}</p>
                        </Motion>
                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .3 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <button className="btn p-20px text-white start-btn">Case Studies<span className="ms-2">
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.3124 8.14797H1.17027H15.3124Z" fill="#6ECADC"/>
                                    <path d="M15.3124 8.14797H1.17027" stroke="#6ECADC" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M15.3124 8.14797H1.17027" stroke="#1B75BB" strokeOpacity="0.2" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.94849 1.78394L15.3124 8.1479L8.94849 14.5119" stroke="#6ECADC" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.94849 1.78394L15.3124 8.1479L8.94849 14.5119" stroke="#1B75BB" strokeOpacity="0.2" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </span></button>
                        </Motion>
                    </div>
                </div>
            </section>
        }
        {
            process_setion &&
            <>
                <section 
                className={
                    serviceName === 'ui-ux-development' ? 'ui-ux-service-process service-process' : 'service-process' &&
                    serviceName === 'blockchain-development' ? 'bg-light-grey service-process' : 'service-process'
                    }>
                    <div className='container-fluid'>
                        <div className='row'>
                            {
                                serviceName === 'ui-ux-development' ?
                                <div className='row mb-5 pb-2 align-items-center'>
                                    <div className='col-lg-12'>
                                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                            <h2 className='mb-4 text-light-grey'>{process_heading}</h2>
                                            <h4 className='text-light-blue'>{process_headingBlue}</h4>
                                        </Motion>
                                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                            <p className='text-black'>Designing intuitive user experiences, user interfaces and digital assets is an artform. We’re experts in mapping a satisfying journey through your apps and websites and creating striking visuals to match.</p>
                                        </Motion>
                                    </div>
                                </div>
                                :
                                <div className='row mb-5 pb-2 align-items-center'>
                                    <div className='col-lg-6'>
                                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                            <div className='process-img'>
                                                <img src={procces_img} className='w-100' alt='img'/>
                                            </div>
                                        </Motion>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                            <h2 className='mb-4 text-light-grey'>{process_heading}</h2>
                                            <h4 className='text-light-blue'>{process_headingBlue}</h4>
                                        </Motion>
                                    </div>
                                </div>

                            }
                            <div className='col-md-6 col-lg-4 mb-4 pb-1'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='process-boxes'>
                                        <div className='mb-2'><img alt='img' src={require('../../assets/images/process-icon-1.svg').default}></img></div>
                                        <h4 className='mb-4 text-light-grey'>{process_card1_heading}</h4>
                                        <ul className='list-unstyled'>
                                            <li>{process_card1_list1}</li>
                                            <li>{process_card1_list2}</li>
                                            <li>{process_card1_list3}</li>
                                            <li>{process_card1_list4}</li>
                                        </ul>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-md-6 col-lg-4 mb-4 pb-1'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='process-boxes'>
                                        <div className='mb-2'><img alt='img' src={require('../../assets/images/process-icon-2.svg').default}></img></div>
                                        <h4 className='mb-4 text-light-grey'>{process_card2_heading}</h4>
                                        <ul className='list-unstyled'>
                                            <li>{process_card2_list1}</li>
                                            <li>{process_card2_list2}</li>
                                            <li>{process_card2_list3}</li>
                                            <li>{process_card2_list4}</li>
                                        </ul>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-md-6 col-lg-4 mb-4 pb-1'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='process-boxes'>
                                        <div className='mb-2'><img alt='img' src={require('../../assets/images/process-icon-3.svg').default}></img></div>
                                        <h4 className='mb-4 text-light-grey'>{process_card3_heading}</h4>
                                        <ul className='list-unstyled'>
                                            <li>{process_card3_list1}</li>
                                            <li>{process_card3_list2}</li>
                                            <li>{process_card3_list3}</li>
                                            <li>{process_card3_list4}</li>
                                        </ul>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-md-6 col-lg-4 mb-4 pb-1'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='process-boxes'>
                                        <div className='mb-2'><img alt='img' src={require('../../assets/images/process-icon-4.svg').default}></img></div>
                                        <h4 className='mb-4 text-light-grey'>{process_card4_heading}</h4>
                                        <ul className='list-unstyled'>
                                            <li>{process_card4_list1}</li>
                                            <li>{process_card4_list2}</li>
                                            <li>{process_card4_list3}</li>
                                            <li>{process_card4_list4}</li>
                                        </ul>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-md-6 col-lg-4 mb-4 pb-1'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='process-boxes'>
                                        <div className='mb-2'><img alt='img' src={require('../../assets/images/process-icon-5.svg').default}></img></div>
                                        <h4 className='mb-4 text-light-grey'>{process_card5_heading}</h4>
                                        <ul className='list-unstyled'>
                                            <li>{process_card5_list1}</li>
                                            <li>{process_card5_list2}</li>
                                            <li>{process_card5_list3}</li>
                                            <li>{process_card5_list4}</li>
                                        </ul>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-md-6 col-lg-4 mb-4 pb-1'>
                                <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='process-boxes'>
                                        <div className='mb-2'><img alt='img' src={require('../../assets/images/process-icon-6.svg').default}></img></div>
                                        <h4 className='mb-4 text-light-grey'>{process_card6_heading}</h4>
                                        <ul className='list-unstyled'>
                                            <li>{process_card6_list1}</li>
                                            <li>{process_card6_list2}</li>
                                            <li>{process_card6_list3}</li>
                                            <li>{process_card6_list4}</li>
                                        </ul>
                                    </div>
                                </Motion>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        }
        {
            flexible &&
            <section className='flexible' style={{ backgroundImage: `url(${flexible_bg})`}}>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6 col-lg-4 col-xl-3 mt-3'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div>
                                    <div className='mb-3'><img alt='img' src={require('../../assets/images/flexible-icon-1.svg').default}></img></div>
                                    <h4 className='mb-2'>Flexible</h4>
                                    <p className='mb-0 col-lg-11 text-white'>
                                        Zodiac’s website designs enable
                                        100% cross-browsers support, are
                                        responsive to support 100% of the
                                        world mobile market .
                                    </p>
                                </div>
                            </Motion>
                        </div>
                        <div className='col-md-6 col-lg-4 col-xl-3 mt-3'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div>
                                    <div className='mb-3'><img alt='img' src={require('../../assets/images/flexible-icon-2.svg').default}></img></div>
                                    <h4 className='mb-2'>Engaging</h4>
                                    <p className='mb-0 col-lg-11 text-white'>
                                        It is not enough to have content if
                                        it is not in well-organized. Organize
                                        in a way that it fascinates and
                                        engage for your audience.
                                    </p>
                                </div>
                            </Motion>
                        </div>
                        <div className='col-md-6 col-lg-4 col-xl-3 mt-3'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div>
                                    <div className='mb-3'><img alt='img' src={require('../../assets/images/flexible-icon-3.svg').default}></img></div>
                                    <h4 className='mb-2'>Self-Evident</h4>
                                    <p className='mb-0 col-lg-11 text-white'>
                                        Let your audience/customers enjoy
                                        an interesting experience of
                                        website navigation with easy
                                        access to any page.
                                    </p>
                                </div>
                            </Motion>
                        </div>
                        <div className='col-md-6 col-lg-4 col-xl-3 mt-3'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div>
                                    <div className='mb-3'><img alt='img' src={require('../../assets/images/flexible-icon-4.svg').default}></img></div>
                                    <h4 className='mb-2'>Human</h4>
                                    <p className='mb-0 col-lg-11 text-white'>
                                        UX is about how much the user is
                                        satisfied, starting from website
                                        ease-of-use and up to user
                                        attaining his/her goals easily.
                                    </p>
                                </div>
                            </Motion>
                        </div>
                    </div>
                </div>
            </section>
        }
        {
            strategy &&
            <>
                <section className='strategy'>
                    <div className='container-fluid'>
                        <div className='row mb-5 justify-content-between align-items-start'>
                            <div className='col-lg-6'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div>
                                        <h2 className='text-light-grey'>Take your mobile app <br /> strategy to the next level</h2>
                                        <h4 className='text-light-blue'>Are you missing out? < br/> There’s no better time than now to think mobile first.</h4>
                                        <div className='col-lg-12 col-sm-6 mx-auto my-2'>
                                            <img src={mobile_app_img} className='w-100' alt='img'></img>
                                        </div>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-lg-6'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div>
                                        <p>
                                        Take your mobile app strategy to the next levelAre you ready to take your mobile app strategy to new heights? Welcome to the forefront of innovation where your app's success knows no bounds. In today's digital landscape, having a mobile app is essential for reaching and engaging with your audience effectively. However, it's not just about having an app; it's about having a winning strategy that sets you apart from the competition.
                                        At ZodiacTechnologies, we specialize in helping businesses like yours elevate their mobile app strategy to achieve unprecedented success. Whether you're a startup aiming to make a splash in the market or an established brand looking to enhance your digital presence, we have the expertise and tools to make it happen.
                                        So, what sets our approach apart?
                                        <br />
                                        * 		Tailored Solutions: We understand that every business is unique, and there's no one-size-fits-all solution when it comes to mobile app strategy. That's why we work closely with you to understand your goals, target audience, and industry landscape to develop a customized strategy that aligns with your objectives.
                                        <br />
                                        * 		User-Centric Design: A great mobile app is more than just functionality; it's about delivering an exceptional user experience. We prioritize user-centric design principles to create intuitive, engaging, and visually stunning apps that captivate users from the moment they launch the app.
                                        </p>
                                    </div>
                                </Motion>
                            </div>
                        </div>
                        <div className='col-lg-10 mx-auto'>
                            <div className='strategy-tag-holder row'>
                                <div className='col-lg-3 col-md-4 col-sm-6'>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <span><img alt='img' src={require('../../assets/images/xamarin-logo-vector 1.svg').default}></img></span>
                                        Xamarin
                                    </Motion>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6'>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <span><img alt='img' src={require('../../assets/images/strategy-icon-2.svg').default}></img></span>
                                        Iconic
                                    </Motion>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6'>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <span><img alt='img' src={require('../../assets/images/strategy-icon-3.svg').default}></img></span>
                                        Flutter
                                    </Motion>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6'>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <span><img alt='img' src={require('../../assets/images/strategy-icon-4.svg').default}></img></span>
                                        React Native
                                    </Motion>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6'>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <span><img alt='img' src={require('../../assets/images/strategy-icon-4.svg').default}></img></span>
                                        Native Script
                                    </Motion>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6'>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <span><img alt='img' src={require('../../assets/images/strategy-icon-6.svg').default}></img></span>
                                        Kotlin
                                    </Motion>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6'>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <span><img alt='img' src={require('../../assets/images/strategy-icon-7.svg').default}></img></span>
                                        Apache Cordova
                                    </Motion>
                                </div>
                                <div className='col-lg-3 col-md-4 col-sm-6'>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <span><img alt='img' src={require('../../assets/images/strategy-icon-8.svg').default}></img></span>
                                        Xamarin
                                    </Motion>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        }

        {
            ideaReality_section &&
            <section className="idea-reality" style={{ backgroundImage: `url(${ideaReality_bg})`, backgroundPosition: `40% center`}}>
                <div className='container'>
                    <div className='row justify-content-end'>
                        <div className='col-lg-6'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <h2 >Are you Ready to turn that idea a reality?</h2>
                            </Motion>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <p>We have been doing this for a long, long time… turning ideas into simply beautiful user experiences, software, websites and apps. We LOVE our job, and you will LOVE the results.
                                What are you waiting for?</p>
                            </Motion>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <button className="btn p-20px start-btn white text-white text-capitalize">Start a Project
                                    <span className="ms-2">
                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M15.3124 8.14797H1.17027H15.3124Z" fill="#6ECADC"/>
                                            <path d="M15.3124 8.14797H1.17027" stroke="#6ECADC" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M15.3124 8.14797H1.17027" stroke="#1B75BB" strokeOpacity="0.2" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.94849 1.78394L15.3124 8.1479L8.94849 14.5119" stroke="#6ECADC" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.94849 1.78394L15.3124 8.1479L8.94849 14.5119" stroke="#1B75BB" strokeOpacity="0.2" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </span>
                                </button>
                            </Motion>
                        </div>
                    </div>
                </div>
            </section>
        }

        {
            ecommerce_success &&

            <>
                <section className='ecom-services'>
                    <div className='container-fluid'>
                        <div className='col-lg-7 mx-auto text-center mb-5 pb-4 text-capitalize'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <h2>E-commerce software development services we provide</h2>
                            </Motion>
                        </div>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 mb-5'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='deed-cards'>
                                        <div>
                                            <img alt='img' src={require('../../assets/images/system-setting 1.svg').default} />
                                        </div>
                                        <h4>E-commerce Website Development</h4>
                                        <p className='f-18px'>We provide comprehensive e-commerce development services, from website design to mobile app development, ensuring seamless shopping experiences for your customers.</p>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-lg-4 col-md-6 mb-5'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='deed-cards'>
                                        <div>
                                            <img alt='img' src={require('../../assets/images/mobility 1.svg').default} />
                                        </div>
                                        <h4>Payment Gateway Integration</h4>
                                        <p className='f-18px'>Our team integrates secure, reliable payment gateways into your e-commerce platform, facilitating smooth transactions and enhancing customer trust.</p>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-lg-4 col-md-6 mb-5'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='deed-cards'>
                                        <div>
                                            <img alt='img' src={require('../../assets/images/devops 1.svg').default} />
                                        </div>
                                        <h4>Shopping Cart Development</h4>
                                        <p className='f-18px'>We develop intuitive shopping cart systems, allowing customers to easily review and modify their orders, leading to higher conversion rates.</p>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-lg-4 col-md-6 mb-5'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='deed-cards'>
                                        <div>
                                            <img alt='img' src={require('../../assets/images/coding (2) 1.svg').default} />
                                        </div>
                                        <h4>Order Management Systems</h4>
                                        <p className='f-18px'>We build efficient order management systems that streamline order processing, inventory tracking, and customer communication, improving business operations.</p>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-lg-4 col-md-6 mb-5'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='deed-cards'>
                                        <div>
                                            <img alt='img' src={require('../../assets/images/selling 1.svg').default} />
                                        </div>
                                        <h4>Multi-channel Selling Solutions</h4>
                                        <p className='f-18px'>We create solutions for selling across multiple channels, including social media and marketplaces, expanding your reach and increasing sales.</p>
                                    </div>
                                </Motion>
                            </div>
                            <div className='col-lg-4 col-md-6 mb-5'>
                                <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                    <div className='deed-cards'>
                                        <div>
                                            <img alt='img' src={require('../../assets/images/analytics 1.svg').default} />
                                        </div>
                                        <h4>E-commerce Analytics and Reporting</h4>
                                        <p className='f-18px'>We build efficient analytics and reports management systems that streamline order processing, inventory tracking, and customer communication, improving business operations.</p>
                                    </div>
                                </Motion>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='ecommerce-success'>
                    <div className='container-fluid'>
                        <div className='col-lg-8 mx-auto text-center'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <h2 className='mb-5 pb-3 text-white'>Maximize Your E-commerce Success With Zodiac Advanced Development Technologies</h2>
                            </Motion>
                        </div>
                        <div className='logo-boxes-main'>
                            <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='logo-boxes'>
                                    <img alt='img' src={require('../../assets/images/tech_icon1.svg').default} />
                                </div>
                            </Motion>
                            <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='logo-boxes'>
                                    <img alt='img' src={require('../../assets/images/tech_icon2.png.svg').default} />
                                </div>
                            </Motion>
                            <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='logo-boxes'>
                                    <img alt='img' src={require('../../assets/images/tech_icon3.png.svg').default} />
                                </div>
                            </Motion>
                            <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='logo-boxes'>
                                    <img alt='img' src={require('../../assets/images/tech_icon4.png.svg').default} />
                                </div>
                            </Motion>
                            <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='logo-boxes'>
                                    <img alt='img' src={require('../../assets/images/angular-logo.svg').default} />
                                </div>
                            </Motion>
                            <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='logo-boxes'>
                                    <img alt='img' src={require('../../assets/images/tech_icon6.png.svg').default} />
                                </div>
                            </Motion>
                            <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='logo-boxes'>
                                    <img alt='img' src={require('../../assets/images/tech_icon7.png.svg').default} />
                                </div>
                            </Motion>
                            <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='logo-boxes'>
                                    <img alt='img' src={require('../../assets/images/vue-logo.svg').default} />
                                </div>
                            </Motion>
                            <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: 1 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='logo-boxes'>
                                    <img alt='img' src={require('../../assets/images/tech_icon3.png.svg').default} />
                                </div>
                            </Motion>
                            <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: 1 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='logo-boxes'>
                                    <img alt='img' src={require('../../assets/images/tech_icon4.png.svg').default} />
                                </div>
                            </Motion>
                            <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: 1 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div className='logo-boxes'>
                                    <img alt='img' src={require('../../assets/images/react-logo.svg').default} />
                                </div>
                            </Motion>
                        </div>
                    </div>
                </section>
            </>
        }

        {
            dev_portfolio &&
            <section className='dev-portfolio'>
                <div className='dev-heading mb-5 pb-3'>
                    <div className='container-fluid'>
                        <div className='col-xl-5 col-lg-7'>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                            <h2>Our Custom Software development Portfolio</h2>
                        </Motion>
                        </div>
                    </div>
                </div>
                <div>
                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                        <OwlCarousel  items= {2.8} margin={20} loop= {true} autoplay ={false} nav ={true} dots= {false} autoplayTimeout= {3000}autoplayHoverPause= {false} autoplaySpeed={2000}
                            responsive={ {'1':{items:1}, '500':{items:2}, '600':{items:2},'1200':{items:2.8}}} id='devportfolio-slides'>
                            <div className="item">
                                <div className='position-relative portfolio-layes-main'>
                                    <div className='portfolio-img-layes'>
                                        <h4>Lorem ipsum dolor sit</h4>
                                        <p className='fw-bold text-light-blue'>Software Development</p>
                                    </div>
                                    <img src={require("../../assets/images/Rectangle 134.png")} alt="" />
                                </div>
                            </div>
                            <div className="item">
                                <div className='position-relative portfolio-layes-main'>
                                    <div className='portfolio-img-layes'>
                                        <h4>Lorem ipsum dolor sit</h4>
                                        <p className='fw-bold text-light-blue'>Software Development</p>
                                    </div>
                                    <img src={require("../../assets/images/Rectangle 134.png")} alt="" />
                                </div>
                            </div>
                            <div className="item">
                                <div className='position-relative portfolio-layes-main'>
                                    <div className='portfolio-img-layes'>
                                        <h4>Lorem ipsum dolor sit</h4>
                                        <p className='fw-bold text-light-blue'>Software Development</p>
                                    </div>
                                    <img src={require("../../assets/images/Rectangle 134.png")} alt="" />
                                </div>
                            </div>
                        </OwlCarousel>
                    </Motion>
                </div>
            </section>
        }

        {
            tech_weUse &&
            <section className={`tech-weUse ${tech_weUse_white ? 'techuse-white' : ''}`} style={{ backgroundImage: `url(${tech_weUse_bg})`}}>
                <div className='container-fluid'>
                    <div className='col-lg-10 mx-auto'>
                        <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>

                            <h2 className='text-light-grey mb-5 pb-3 text-center'>Technologies & Tools We Use</h2>
                        </Motion>
                        
                        <div className='tab-btn-main'>
                            <div onClick={()=> handleactiveClick(0)} className={activeTab === 0 ? "active tab-btn" : 'tab-btn' }>Web App Frameworks</div>
                            <div onClick={()=> handleactiveClick(1)} className={activeTab === 1 ? "active tab-btn" : 'tab-btn' }>Tools and Libraries</div>
                        </div>
                        
                        {
                            activeTab === 0 &&
                            <div className='tab-contents'>
                                <div className='tech-main'>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/react-logo.svg').default} />
                                            </div>
                                            <h4>Redux</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/angular-logo.svg').default} />
                                            </div>
                                            <h4>AngularJS</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/vue-logo.svg').default} />
                                            </div>
                                            <h4>VueJS</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/next-logo.svg').default} />
                                            </div>
                                            <h4>NextJS</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/svelte-logo.svg').default} />
                                            </div>
                                            <h4>SvelteJS</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/solidjs-logo.svg').default} />
                                            </div>
                                            <h4>SolidJS</h4>
                                        </div>
                                    </Motion>
                                </div>
                            </div>
                        }
                        {
                            activeTab === 1 &&
                            <div className='tab-contents'>
                                <div className='tech-main'>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/react-logo.svg').default} />
                                            </div>
                                            <h4>Redux</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/mobx-logo.svg').default} />
                                            </div>
                                            <h4>Mobx/MST</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/material-logo.svg').default} />
                                            </div>
                                            <h4>Material UI</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/typescript-logo.svg').default} />
                                            </div>
                                            <h4>TypeScript</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/eslint-logo.svg').default} />
                                            </div>
                                            <h4>ESLint</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/webpack-logo.svg').default} />
                                            </div>
                                            <h4>Webpack</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/babel-logo.svg').default} />
                                            </div>
                                            <h4>Babel</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/firebase-logo.svg').default} />
                                            </div>
                                            <h4>Firebase</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/vscode-logo.svg').default} />
                                            </div>
                                            <h4>VS Code</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/gql-logo.svg').default} />
                                            </div>
                                            <h4>GraphQL</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/jest-logo.svg').default} />
                                            </div>
                                            <h4>Jest</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/sonar-logo.svg').default} />
                                            </div>
                                            <h4>SonarCube</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: 1 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/axios-logo.svg').default} />
                                            </div>
                                            <h4>Axios</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: 1 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/db-logo.svg').default} />
                                            </div>
                                            <h4>D3.js</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: 1 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/apolo-logo.svg').default} />
                                            </div>
                                            <h4>ApoloCli-ent</h4>
                                        </div>
                                    </Motion>
                                    <Motion classNames="transition-none h-100" transition={{ duration: 0.4, delay: 1 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <div className='tech-boxes'>
                                            <div className='tech-boxes-img'>
                                                <img alt='img' src={require('../../assets/images/tailwind-logo.svg').default} />
                                            </div>
                                            <h4>TailwindCSS</h4>
                                        </div>
                                    </Motion>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        }

        {
            shipment_section &&
            <>
                <section className='software-shipment'>
                    <div className='container-fluid'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6'>
                                <div>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <h2 className='text-light-grey text-capitalize'>Ship software on time,with Zodiac at your side</h2>
                                        <h4 className='text-light-blue mt-3 mb-4'>Together we’ll build great apps.</h4>
                                    </Motion>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <p className='font-sofia'>
                                        Zodiac Technology’s team of software development professionals build customized software designed to meet any and all your business goals and processes.
                                        Our software development services are designed to deliver the highest quality possible, made possible through rigorous checks-and-balances at every step along the development life-cycle.
                                        We are agile and nimble. Routine calls are efficient and agenda-driven. Unlike others, who turn a simple conference call into a billable all-hands meeting, Zodiac Technologies goal is to simply get things done, always.
                                        </p>
                                    </Motion>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-7 mx-auto'>
                                <div>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .3 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <img src={softwareShip_img} className='w-100' alt='img'></img>
                                    </Motion>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='leaders'>
                    <div className='container-fluid'>
                        <div className='row align-items-center'>
                            <div className='col-lg-5 col-md-7 mx-auto'>
                                <div>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <img alt='img' src={require('../../assets/images/meditation-man.png')} className='w-100'></img>
                                    </Motion>
                                </div>
                            </div>
                            <div className='col-lg-7'>
                                <div>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <h2 className='text-capitalize text-light-grey'>Leaders in software development, enterprise system integrations, consulting and managed teams</h2>
                                        <h4 className='text-light-blue mb-4'>You’re in good hands.</h4>
                                    </Motion>
                                    <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                        <p>Zodiac Technology is a leader in enterprise software development services and the software company of choice for enterprises, SMBs, startup, federal, non-profit and trade organizations.
                                        We deliver efficient and effective solutions that fulfill the technical needs and requirements of the most dynamic and complex organizations in the world.</p>
                                    </Motion>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        }

        {
            blockchain_product &&
            <section className='blockchain-product'>
                <div className='container-fluid'>
                    <Motion classNames="transition-none" transition={{ duration: 0.4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                        <h2 className='text-white mb-5 pb-4'>Our Blockchain Products</h2>
                    </Motion>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-4 col-6 mb-5 pb-4'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div>
                                    <img alt='img' src={require('../../assets/images/Group 162.svg').default} />
                                    <p>NFT <br /> Marketplace</p>
                                </div>
                            </Motion>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-6 mb-5 pb-4'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .2 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div>
                                    <img alt='img' src={require('../../assets/images/decentralized-finance-defi 1.svg').default} />
                                    <p>Defi Lending<br /> Software</p>
                                </div>
                            </Motion>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-6 mb-5 pb-4'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div>
                                    <img alt='img' src={require('../../assets/images/crypto-wallet 1.svg').default} />
                                    <p>Crypto Wallet<br /> Software</p>
                                </div>
                            </Motion>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-6 mb-5 pb-4'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .4 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div>
                                    <img alt='img' src={require('../../assets/images/exchange 1.svg').default} />
                                    <p>OTG Exchange <br /> Software</p>
                                </div>
                            </Motion>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-6 mb-5 pb-4'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div>
                                    <img alt='img' src={require('../../assets/images/dashboard 1.svg').default} />
                                    <p>Automatic/Manual ICO<br /> Dashboard</p>
                                </div>
                            </Motion>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-6 mb-5 pb-4'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .6 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div>
                                    <img alt='img' src={require('../../assets/images/Group 163.svg').default} />
                                    <p>Decentralized Exchange<br /> (DEX)</p>
                                </div>
                            </Motion>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-6 mb-5 pb-4'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div>
                                    <img alt='img' src={require('../../assets/images/token 1.svg').default} />
                                    <p>Token Migration<br /> platform</p>
                                </div>
                            </Motion>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-6 mb-5 pb-4'>
                            <Motion classNames="transition-none" transition={{ duration: 0.4, delay: .8 }} variants={{ hidden: { opacity: 0, x: -20 }, visible: { opacity: 1, x: 0 } }}>
                                <div>
                                    <img alt='img' src={require('../../assets/images/token (1) 1.svg').default} />
                                    <p>Token Bridge<br /> platform</p>
                                </div>
                            </Motion>
                        </div>
                    </div>
                </div>
            </section>
        }

        <ContactSection />
    </>
  );
};

export default Services;
