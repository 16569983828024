import React from 'react'
import '../../assets/css/style.css'

const ScrollTop = () => {
    const scrollTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };
  return (
    <>
    <p type="button" className="top-btn text-decoration-none text-light-blue" onClick={scrollTop}>
      <span className="">
        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.125 8.56923L8.68056 3.01367L14.2361 8.56923" stroke="#74CEE0" strokeWidth="2.77778" strokeMiterlimit="10" strokeLinecap="square"/>
        </svg>
      </span>
    </p> 
    </>
  )
}

export const ScrollBottom = () => {
    const scrollbottom = () => {
        window.scrollTo({
          top: 1000,
          behavior: 'smooth'
        });
      };
  return (
    <>
      <div className="scrol-btn">
            <p type="button" onClick={scrollbottom} className="text-decoration-none text-dark-100 d-flex align-items-center">
              <span className="w-48 h-48 me-3 d-flex justify-content-center align-items-center">
                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_4_20)">
                  <path d="M6 1V14" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M1 14H11L6 21L1 14Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_4_20">
                  <rect width="12" height="22" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </span>Scroll down</p>
        </div>
    </>
  )
}

export default ScrollTop
